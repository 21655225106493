import React from 'react';
import {Container} from "@mui/material";
import CenterModuleNewLock from "../components/CenterModuleNewLock";
import {AppProps} from "../Shared";

const NewLock: React.FC<AppProps> = ({ authStatus }) => {
  return (
    <Container>
      <CenterModuleNewLock authStatus={ authStatus } />
    </Container>
  );
};

export default NewLock;
