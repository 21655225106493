import React from 'react';
import {Typography, Box, Container, Grid} from '@mui/material';
import {AppProps} from "../Shared";

const StaticTextPage: React.FC<AppProps> = ({authStatus}) => {
    return (
        <Container>
            <>
                <Box mt={4}>
                    <Grid container spacing={2}>
                        {/* 4-column section */}
                        <Grid item xs={12} md={12} style={{paddingLeft: '30px'}}>
                            <Typography variant="h3" style={{color: '#fff', marginBottom: '20px', fontWeight: '900'}}>
                                Privacy Policy
                            </Typography>

                            <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                                At Moon or Dust, we respect your privacy and are committed to protecting your personal
                                information. This Privacy Policy explains how we collect, use, and disclose information
                                about you when you use our services.
                            </Typography>

                            <Typography variant="h5" style={{color: '#f1f0e1', marginTop: '20px'}}>
                                1. Information We Collect
                            </Typography>
                            <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '10px'}}>
                                We may collect the following information when you use our services:
                            </Typography>
                            <ul style={{color: '#9c9b93', marginBottom: '20px'}}>
                                <li>Your wallet address or account details (for blockchain transactions)</li>
                                <li>Information you provide when you interact with our services</li>
                                <li>Technical data such as your IP address, browser type, and operating system</li>
                            </ul>

                            <Typography variant="h5" style={{color: '#f1f0e1', marginTop: '20px'}}>
                                2. How We Use Your Information
                            </Typography>
                            <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '10px'}}>
                                We use the information we collect to:
                            </Typography>
                            <ul style={{color: '#9c9b93', marginBottom: '20px'}}>
                                <li>Provide and improve our services</li>
                                <li>Facilitate blockchain transactions</li>
                                <li>Ensure the security of our platform</li>
                            </ul>

                            <Typography variant="h5" style={{color: '#f1f0e1', marginTop: '20px'}}>
                                3. Sharing Your Information
                            </Typography>
                            <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                                We do not sell or share your personal information with third parties, except as
                                necessary to provide our services or comply with legal obligations.
                            </Typography>

                            <Typography variant="h5" style={{color: '#f1f0e1', marginTop: '20px'}}>
                                4. Data Security
                            </Typography>
                            <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                                We take reasonable measures to protect your information from unauthorized access,
                                alteration, or destruction.
                            </Typography>

                            <Typography variant="h5" style={{color: '#f1f0e1', marginTop: '20px'}}>
                                5. Your Rights
                            </Typography>
                            <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                                You have the right to access, modify, or delete your personal information. If you have
                                any questions or concerns about our privacy practices, please contact us.
                            </Typography>

                            <Typography variant="h5" style={{color: '#f1f0e1', marginTop: '20px'}}>
                                6. Changes to This Policy
                            </Typography>
                            <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                                We may update this Privacy Policy from time to time. Any changes will be posted on this
                                page, so please review it regularly.
                            </Typography>

                            <Typography variant="body1" style={{color: '#9c9b93'}}>
                                If you have any questions about this Privacy Policy, please contact us at <a
                                href="mailto:support@moonordust.io" style={{color: '#ff007a'}}>support@moonordust.io</a>.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </>
        </Container>
    );
};

export default StaticTextPage;
