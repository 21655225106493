import React from 'react';
import {Box, Container, Link, Typography} from '@mui/material';
import {NavLink} from "react-router-dom";
const Footer: React.FC = () => (
      <Box bgcolor="#1f1f22">
        <Container>
            <Box display="flex" justifyContent="space-between" mt={4} p={2} bgcolor="#1f1f22" style={{margin: '16px'}}>
                <Typography style={{ color: '#98939c' }}>© 2024 Moon or Dust</Typography>
                <Box>
                    <Typography style={{color: '#98939c', marginRight: '10px', display: 'inline-block'}}><Link href={"#"} className={"footer-links"}>Community
                        Channel</Link><span style={{marginLeft: '10px'}}>∙</span></Typography>
                    <Typography style={{color: '#98939c', marginRight: '10px', display: 'inline-block'}}><NavLink to="/terms-and-conditions" className="footer-links">Terms and Conditions</NavLink><span
                        style={{marginLeft: '10px'}}>∙</span></Typography>
                    <Typography style={{color: '#98939c', marginRight: '10px', display: 'inline-block'}}><NavLink to="/privacy-policy" className="footer-links">Privacy Policy</NavLink><span
                        style={{marginLeft: '10px'}}>∙</span></Typography>
                    <Typography style={{color: '#98939c', display: 'inline-block' }}><Link href={"#"} className={"footer-links"}>Need Support?</Link></Typography>
                </Box>
            </Box>
        </Container>
      </Box>
);

export default Footer;
