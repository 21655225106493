import React, {useState, useEffect} from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Dialog,
    Snackbar,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useAccount} from 'wagmi';
import {useWeb3jsSigner} from './useWeb3js';
import {AppProps, INFINITY_DAYS, LOCKER_ABI, shortNumber} from "../Shared";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BarChartIcon from '@mui/icons-material/BarChart';
import moment from 'moment';

interface LockData {
    tokenName: string;
    token: string;
    conditions: string;
    status: string;
    lockId: string;
    tokenData: any;
    _id: string;
    tokensLocked: number;
    lockTime: number;
    priceMultiplier: number;
    emergencyWithdrawEnabled?: boolean;
    multiplier: number;
    amount: string;
    unlockPrice?: number;
    unlockPriceTimestamp?: string;
    unlockReason?: string;
    unlockTimestamp?: string;
    userUnlockReason?: string;
    userUnlockTimestamp?: string;
    currentValue?: string;
    destinationValue?: string;
}

// Helper function to check if lock time is in the past and format time
const formatTimeRemaining = (lockTime: number) => {
    const now = moment();
    const unlockTime = moment.unix(lockTime);

    if (now.isAfter(unlockTime)) {
        const duration = moment.duration(now.diff(unlockTime));
        const years = Math.floor(duration.asYears());
        const months = Math.floor(duration.asMonths()) % 12;  // Months after subtracting years
        const days = Math.floor(duration.asDays()) % 30;  // Days after subtracting years and months
        const hours = duration.hours();
        const minutes = duration.minutes();

        if (years > 0) return `${years} year(s) ago`;
        if (months > 0) return `${months} month(s) ago`;
        if (days > 0) return `${days} day(s) ago`;
        if (hours > 0) return `${hours} hour(s) ago`;
        return `${minutes} minute(s) ago`;
    }

    // Get the time remaining for unlock
    const duration = moment.duration(unlockTime.diff(now));
    const years = Math.floor(duration.asYears());
    const months = Math.floor(duration.asMonths()) % 12;
    const days = Math.floor(duration.asDays()) % 30;
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (years > 0) return `in ${years} year(s)`;
    if (months > 0) return `in ${months} month(s)`;
    if (days > 0) return `in ${days} day(s)`;
    if (hours > 0) return `in ${hours} hour(s)`;
    return `in ${minutes} minute(s)`;
};


// Function to generate lock status wording
const generateLockStatus = (lock: any) => {
    const currentTime = moment().unix();
    const lockTimeRemaining = currentTime < lock.lockTime && lock.lockTime !== 0;
    const priceConditionMet = Number(lock.tokenData.priceusd) >= (Number(lock.priceToday) * lock.multiplier);
    const lockedTokens: any = BigInt(lock.amount) / BigInt(Math.pow(10, lock.tokenData.decimals));
    const isLocked: boolean = lock.status === 'locked';
    const isUnlockable: boolean = lock.status === 'unlockable';
    const isUnlocked: boolean = lock.status === 'unlocked';
    const lockTime = Number(lock.lockTime);
    const isInfinity = INFINITY_DAYS * 60 * 60 * 24 === lockTime;
    const lockTxExplorer = `https://etherscan.io/tx/${lock.lockTxHash}`;

    /*
    Scenarios:
        1. status = locked, timeLock = 0 or infinity, price conditions not met
        2. status = locked, timeLock = 0, price conditions inactive, unlockable anytime
        3. status = locked, timeLock = infinity, price conditions inactive, never unlockable
        4. status = unlockable, unlock reason price
        5. status = unlockable, unlock reason time
        6. status = unlocked, unlock reason emergency
        7. status = unlocked, unlock reason user
        8. status = locked, timeLock = specific, price conditions inactive, time remaining
        9. status = locked, timeLock = specific, price conditions specific, time remaining
     */

    // Display "+XX% since lock", if price increased make it green, if decreased make it red
    function calculatePriceComparison(currentPrice: number, lockedPrice: number) {
        const priceChange = ((currentPrice - lockedPrice) / lockedPrice) * 100;
        return (priceChange > 0 ? '+' : '') + priceChange.toFixed(2) + '%';
    }
    const priceChange = calculatePriceComparison(Number(lock.tokenData.priceusd), Number(lock.priceToday));
    const priceChangeColor = priceChange.includes('-') ? 'red' : 'green';
    const priceChangeMsg = <span style={{color: priceChangeColor, fontWeight: '900'}}>{priceChange}</span>;

    // 1.
    if (isLocked && (lockTime === 0 || isInfinity) && lock.multiplier > 0) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                ⭐️ Tokens will be available to unlock when token's price
                reaches <b>${Number(lock.priceToday) * lock.multiplier}</b>
                <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock) MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                {
                  lock.currentValue ? (
                    <span>📈 Current value is <b>${lock.currentValue}</b></span>
                  ) : ''
                }
                {
                  lock.destinationValue ? (
                    <span style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                  ) : ''
                }
            </>
        );
    }
    // 2.
    if (isLocked && lock.multiplier === 0 && lockTime === 0) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                ⭐️ Tokens will be available to unlock shortly, refresh this page in few moments
            </>
        );
    }
    // 3.
    if (isLocked && lock.multiplier === 0 && isInfinity) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                {lock.emergencyWithdrawEnabled ? '🔐' : '🔒'} Tokens are locked
                forever{lock.emergencyWithdrawEnabled ? ' untill emergency withdrawal is used' : ' with emergency withdrawal disabled'}
            </>
        );
    }
    // 4.
    if (isUnlockable && lock.unlockReason === 'price') {
        const unlockPriceDate = new Date(Number(lock.unlockPriceTimestamp)).toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                🟢 Tokens can be unlocked now because the token's price reached
                ${(Number(lock.priceToday) * lock.multiplier)} on {unlockPriceDate}
                <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock) MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                {
                  lock.currentValue ? (
                    <span>📈 Current value is <b>${lock.currentValue}</b></span>
                  ) : ''
                }
                {
                  lock.destinationValue ? (
                    <span style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                  ) : ''
                }
            </>
        );
    }
    // 5.
    if (isUnlockable && lock.unlockReason === 'time') {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                🟢 Tokens can be unlocked now because the the lock time has passed
                <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock) MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                {
                  lock.currentValue ? (
                    <span>📈 Current value is <b>${lock.currentValue}</b></span>
                  ) : ''
                }
                {
                  lock.destinationValue ? (
                    <span style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                  ) : ''
                }
            </>
        );
    }
    // 6.
    if (isUnlocked && lock.userUnlockReason === 'emergency') {
        const unlockTxExplorer = `https://etherscan.io/tx/${lock.userUnlockTxHash}`;
        const userUnlockDate = new Date(Number(lock.userUnlockTimestamp)).toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                ✅ Tokens were unlocked with Emergency Withdraw on {userUnlockDate} <a href={unlockTxExplorer}
                                                                                      target="_blank"
                                                                                      rel="noreferrer">🔗</a>
            </>
        );
    }
    // 7.
    if (isUnlocked && lock.userUnlockReason === 'user') {
        const unlockTxExplorer = `https://etherscan.io/tx/${lock.userUnlockTxHash}`;
        const userUnlockDate = new Date(Number(lock.userUnlockTimestamp)).toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                ✅ Tokens were unlocked on {userUnlockDate} <a href={unlockTxExplorer}
                                                              target="_blank"
                                                              rel="noreferrer">🔗</a>
            </>
        );
    }
    // 8.
    if (isLocked && (lockTime !== 0 && !isInfinity) && lock.multiplier === 0) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                ⭐️ Tokens will be available to unlock {formatTimeRemaining(lockTime)}
                <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock) MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                {
                  lock.currentValue ? (
                    <span>📈 Current value is <b>${lock.currentValue}</b></span>
                  ) : ''
                }
                {
                  lock.destinationValue ? (
                    <span style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                  ) : ''
                }
            </>
        );
    }
    // 9.
    if (isLocked && (lockTime !== 0 && !isInfinity) && lock.multiplier > 0) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer">🔗</a>
                <br/>
                ⭐️ Tokens will be available to unlock {formatTimeRemaining(lockTime)} or when price
                reaches <b>${Number(lock.priceToday) * lock.multiplier}</b>
                <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock) MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                {
                  lock.currentValue ? (
                    <span>📈 Current value is <b>${lock.currentValue}</b></span>
                  ) : ''
                }
                {
                  lock.destinationValue ? (
                    <span style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                  ) : ''
                }
            </>
        );
    }
};

const MyLocks: React.FC<AppProps> = ({authStatus}) => {
    const {address, isConnected} = useAccount();
    const web3jsSigner = useWeb3jsSigner();
    const [locks, setLocks] = useState<LockData[]>([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState<string | null>(null);
    const [selectedLockId, setSelectedLockId] = useState<string | null>(null);
    const navigate = useNavigate();

    // Fetch user's locks from the backend
    const fetchLocks = async () => {
        try {
            if(authStatus === 'authenticated') {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/my-locks', {
                    method: 'GET',
                    credentials: 'include',
                });
                const data = await response.json();
                setLocks(data.locks);
            }
        } catch (error) {
            console.error('Error fetching locks:', error);
            setLocks([]);
        }
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (address && isConnected && authStatus === "authenticated") {
            fetchLocks(); // Initial fetch
            intervalId = setInterval(() => {
                fetchLocks();
            }, 30000); // Fetch every 30 seconds
        } else {
            setLocks([]); // Clear locks if not authenticated or disconnected
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId); // Clean up the interval when component unmounts
            }
        };
    }, [authStatus]);

    const updateLockBackend = async (lockDetails: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/lock-update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(lockDetails),
                credentials: 'include'
            });

            const data = await response.json();
            if (data.success) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    // Handle unlock action
    const handleUnlock = async (lockId: string) => {
        try {
            let unlockHash: any;
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/my-lock-emergency-hash', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({lockId})
                });
                const data = await response.json();

                if (data && data.emergencyHash) {
                    unlockHash = data.emergencyHash;
                }
            } catch (error) {
                console.error('Error fetching locks:', error);
            }

            if (!unlockHash) {
                throw new Error("Error fetching unlock data, try again later or contact support.");
            }
            const lockerAddress = process.env.REACT_APP_LOCKER_ADDRESS;
            const lockerContract = new web3jsSigner.eth.Contract(LOCKER_ABI, lockerAddress);

            const unlockTransaction = lockerContract.methods.unlockTokens(lockId, unlockHash);
            const unlockTxHash: any = await unlockTransaction.send({
                from: address,
            });

            setShowSuccess(true);

            await updateLockBackend({
                newStatus: "unlocked",
                unlockReason: "user",
                unlockTxHash: unlockTxHash.transactionHash ? unlockTxHash.transactionHash : unlockTxHash,
                lockId
            });

            await fetchLocks();
        } catch (error) {
            console.error('Error unlocking tokens', error);
            setShowError('An error occurred while unlock. Try again later or contact support.');
        }
    };

    // Handle emergency withdraw action
    const handleEmergencyWithdraw = async (lockId: string) => {
        try {
            let emergencyHash: any;
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/my-lock-emergency-hash', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({lockId})
                });
                const data = await response.json();

                if (data && data.emergencyHash) {
                    emergencyHash = data.emergencyHash;
                }
            } catch (error) {
                console.error('Error fetching locks:', error);
            }

            if (!emergencyHash) {
                throw new Error("Error fetching emergency hash, try again later or contact support.");
            }

            const lockerAddress = process.env.REACT_APP_LOCKER_ADDRESS;
            const lockerContract = new web3jsSigner.eth.Contract(LOCKER_ABI, lockerAddress);

            const emergencyWithdrawTransaction = lockerContract.methods.emergencyWithdraw(lockId, emergencyHash);
            const unlockTxHash: any = await emergencyWithdrawTransaction.send({
                from: address,
            });

            setShowSuccess(true);

            await updateLockBackend({
                newStatus: "unlocked",
                unlockReason: "emergency",
                unlockTxHash: unlockTxHash.transactionHash ? unlockTxHash.transactionHash : unlockTxHash,
                lockId
            });

            await fetchLocks();
        } catch (error) {
            console.error('Error during emergency withdraw', error);
            setShowError('An error occurred during emergency withdrawal.');
        }
    };

    // Handle close of success popup
    const handleCloseSuccess = () => {
        setShowSuccess(false);
        navigate('/my-locks');
    };

    // Handle close of error notification
    const handleCloseError = () => {
        setShowError(null);
    };

    // Copy token address to clipboard
    const handleCopy = (token: string) => {
        navigator.clipboard.writeText(token);
    };

    // Calculate comparison between destination and current price
    const calculatePriceComparison = (destinationPrice: number, currentPrice: number) => {
        const difference = ((destinationPrice - currentPrice) / currentPrice) * 100;
        return difference.toFixed(2) + '%';
    };

    return (
        <Box mt={4}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3"
                                style={{color: '#fff', marginLeft: '20px', marginBottom: '20px', fontWeight: '900'}}>
                        My <span className="pink">Locks</span>
                    </Typography>

                    <TableContainer component={Paper}
                                    style={{backgroundColor: '#1f1f22', paddingLeft: '20px', paddingRight: '20px'}}
                                    sx={{
                                        overflowX: 'auto', // Enable horizontal scrolling on smaller screens
                                    }}>
                        <Table aria-label="my locks table" style={{width: '100%'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            color: '#fff',
                                            fontSize: '1em',
                                            fontWeight: 'bold',
                                            minWidth: '150px'  // Minimum width to make sure it adjusts responsively
                                        }}>Token</TableCell>
                                    <TableCell style={{
                                        color: '#fff',
                                        fontSize: '1em',
                                        fontWeight: 'bold',
                                        maxWidth: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        minWidth: '400px'  // Ensures the column doesn't get too small
                                    }}>Conditions</TableCell>
                                    <TableCell style={{
                                        color: '#fff',
                                        fontSize: '1em',
                                        fontWeight: 'bold',
                                        minWidth: '50px' // Ensure some minimum width for Status column
                                    }}>Status</TableCell>
                                    <TableCell style={{
                                        color: '#fff',
                                        fontSize: '1em',
                                        fontWeight: 'bold',
                                        minWidth: '100px'  // Ensure some minimum width for Actions
                                    }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {locks && locks.length > 0 ? (
                                    locks.map((lock, index) => (
                                        <TableRow key={lock._id}>
                                            <TableCell style={{
                                                color: '#fff',
                                                borderBottom: index === locks.length - 1 ? 'none' : '1px solid #333',
                                                minWidth: '150px'
                                            }}>
                                                <Typography variant="body1"
                                                            style={{fontWeight: 'bold'}}>{lock.tokenData.name}</Typography>
                                                <Typography variant="body2" style={{fontSize: '0.8em'}}>
                                                    {lock.token.slice(0, 6)}...{lock.token.slice(-4)}
                                                    <IconButton onClick={() => handleCopy(lock.token)}
                                                                style={{color: '#fff', marginLeft: '5px'}}>
                                                        <ContentCopyIcon fontSize="small"/>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => window.open(`https://dexscreener.com/ethereum/${lock.tokenData.pool}`, '_blank')}
                                                        style={{color: '#fff', marginLeft: '0px'}}>
                                                        <BarChartIcon fontSize="small"/>
                                                    </IconButton>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{
                                                color: '#fff',
                                                borderBottom: index === locks.length - 1 ? 'none' : '1px solid #333',
                                                maxWidth: '100%',
                                                minWidth: '400px'
                                            }}>
                                                {generateLockStatus(lock)}
                                            </TableCell>
                                            <TableCell style={{
                                                color: '#fff',
                                                borderBottom: index === locks.length - 1 ? 'none' : '1px solid #333',
                                                minWidth: '50px'
                                            }}>
                                                {lock.status}
                                            </TableCell>
                                            <TableCell style={{
                                                color: '#fff',
                                                borderBottom: index === locks.length - 1 ? 'none' : '1px solid #333',
                                                minWidth: '100px'
                                            }}>
                                                {lock.status === 'unlockable' && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleUnlock(lock.lockId)}
                                                        style={{marginRight: '10px'}}
                                                        sx={{
                                                            color: '#ffffff',
                                                            backgroundColor: '#ff007a',
                                                            borderRadius: '0px',
                                                            fontWeight: '900',
                                                            '&:hover': {
                                                                backgroundColor: '#d6005a',
                                                            }
                                                        }}
                                                    >
                                                        Unlock
                                                    </Button>
                                                )}
                                                {lock.emergencyWithdrawEnabled && lock.status === 'locked' && (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handleEmergencyWithdraw(lock.lockId)}
                                                        sx={{
                                                            color: '#ff007a',
                                                            backgroundColor: '#333',
                                                            borderRadius: '0px',
                                                            fontWeight: '900',
                                                            '&:hover': {
                                                                backgroundColor: '#222',
                                                                color: '#fff',
                                                            }
                                                        }}
                                                    >
                                                        Emergency Withdraw
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))) : (
                                    <TableRow>
                                        <TableCell colSpan={4}
                                                   style={{color: '#fff', textAlign: 'center', borderBottom: 'none'}}>
                                            { authStatus === 'authenticated' ? 'No locks found.' : 'Please sign in first.' }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Grid>

            {/* Success Popup */}
            <Dialog open={showSuccess} onClose={handleCloseSuccess} sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: '#1f1f22', // Dark background color
                    color: '#ffffff', // Text color
                }
            }}>
                <DialogTitle sx={{color: '#ff007a'}}>Action Successful</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{color: '#ffffff'}}>
                        Your action has been completed successfully!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccess} sx={{
                        color: '#ffffff',
                        backgroundColor: '#ff007a',
                        borderRadius: '0px',
                        fontWeight: '900',
                        '&:hover': {
                            backgroundColor: '#d6005a', // Darken button on hover
                        }
                    }}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Error Snackbar */}
            <Snackbar
                open={!!showError}
                autoHideDuration={6000}
                onClose={handleCloseError}
                message={showError}
            />
        </Box>
    );
};

export default MyLocks;
