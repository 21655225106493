import React from 'react';
import {Box, Container} from '@mui/material';
import TopRow from './components/TopRow';
import Footer from './components/Footer';
import {RainbowKitProvider, darkTheme} from '@rainbow-me/rainbowkit';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import '@rainbow-me/rainbowkit/styles.css';
import NewLock from "./pages/NewLock";
import TermsAndConditions from "./pages/TermsAndConditions";
import MyLocks from "./pages/MyLocks";
import {AppProps} from "./Shared";
import ScrollToTop from "./components/ScrollToTop";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App: React.FC<AppProps> = ({authStatus}) => {
    return (
        <Router>
            <ScrollToTop/>
            <RainbowKitProvider theme={darkTheme({
                accentColor: '#ff007a',
                borderRadius: 'none',
            })} modalSize="compact" appInfo={{appName: 'Moon or Dust'}}>
                <Container maxWidth={false} disableGutters>
                    <Box sx={{bgcolor: '#101015', minHeight: '100vh', width: '100vw', color: '#ffffff'}}>
                        {/* Top Row */}
                        <TopRow authStatus={authStatus}/>
                        <Routes>

                            <Route path="/" element={<Home authStatus={authStatus}/>}/>
                            <Route path="/new-lock" element={<NewLock authStatus={authStatus}/>}/>
                            <Route path="/my-locks" element={<MyLocks authStatus={authStatus}/>}/>
                            <Route path="/terms-and-conditions"
                                   element={<TermsAndConditions authStatus={authStatus}/>}/>
                            <Route path="/privacy-policy"
                                   element={<PrivacyPolicy authStatus={authStatus}/>}/>
                            {/*<Route path="/static-text" element={<StaticTextPage />} />*/}
                        </Routes>

                        {/* Footer */}
                        <Footer/>
                    </Box>
                </Container>
            </RainbowKitProvider>
        </Router>
    );
};

export default App;
