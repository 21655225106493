import React from 'react';
import { Typography, Box } from '@mui/material';

const TopCenter: React.FC = () => (
  <Box>
    <Typography variant="h6" style={{ color: '#ffffff' }}></Typography>
  </Box>
);

export default TopCenter;
