import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import LockClockIcon from '@mui/icons-material/LockClock';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

const CenterModule: React.FC = () => (
  <Box mt={4}>
    <Grid container spacing={2}>
      {/* 8-column section #1f1f22 #3a2d40 */}
      <Grid item xs={12} md={12}>
        <Typography variant="h1" style={{ color: '#ffffff', fontSize: '5rem', fontWeight: '900', backgroundColor: '#ff007a', padding: '20px' }}>
            Your tokens, your rules <span style={{ color: '#fff' }}>★</span> <span  className={"main-underline"}>lock tokens</span> for time or price, securely and effortlessly.
        </Typography>
      </Grid>

      {/* 4-column section */}
      <Grid item xs={12} md={12}>
        <Box style={{ backgroundColor: '#1f1f22', padding: '10px 20px', borderRadius: '0px', position: 'relative' }}>

          <Box display="flex" alignItems="start" my={2}>
            <LooksOneIcon fontSize="large" style={{ color: '#ffffff' }} />
            <Typography variant="h6" style={{ color: '#ffffff', marginLeft: '10px' }}>
              Login with your favourite wallet
            </Typography>
          </Box>

          <Box display="flex" alignItems="start" my={2}>
            <LooksTwoIcon fontSize="large" style={{ color: '#ffffff' }} />
            <Typography variant="h6" style={{ color: '#ffffff', marginLeft: '10px' }}>
              Enter the ERC20 token address you want to lock
            </Typography>
          </Box>

          <Box display="flex" alignItems="start" my={2}>
            <Looks3Icon fontSize="large" style={{ color: '#ffffff' }} />
            <Typography variant="h6" style={{ color: '#ffffff', marginLeft: '10px' }}>
              Set the time or price conditions for unlocking
            </Typography>
          </Box>

          <Box display="flex" alignItems="start" my={2}>
            <BeachAccessIcon fontSize="large" style={{ color: '#ffffff' }} />
            <Typography variant="h6" style={{ color: '#ffffff', marginLeft: '10px' }}>
              Relax and let the market do the work!
            </Typography>
          </Box>

          {/* Right Bottom Background Icon */}
          <LockClockIcon
            style={{
              position: 'absolute',
              right: '10px',
              bottom: '10px',
              fontSize: '10rem', // Adjust size as needed
              color: '#ffffff20', // Use transparency for a background effect
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={12}>
        <Box style={{ backgroundColor: '#1f1f22', padding: '10px 20px', borderRadius: '0px', position: 'relative', paddingBottom: '50px' }}>

          <Typography variant="h3" style={{ color: '#ffffff', marginLeft: '10px', fontWeight: '900', marginTop: '20px' }}>
            <span className={"pink"}>Moon or Dust</span> Simple and Secure Token Locking
          </Typography>

          <Typography variant="body1" style={{ color: '#ffffff', marginLeft: '10px', marginTop: '20px', fontSize: '1.2em' }}>
            Moon or Dust is a decentralized application that allows users to <span className={"pink"}>lock their ERC20 tokens</span> on the Ethereum blockchain in a secure and transparent way. Using our smart contract, you can set specific conditions for unlocking your tokens, such as a <span className={"pink"}>set date</span> or a combination of time and <span className={"pink"}>price/market cap targets</span>. This gives you control over your tokens and helps you manage your assets based on market conditions.</Typography>

          <Typography variant="body1" style={{ color: '#ffffff', marginLeft: '10px', marginTop: '20px', fontSize: '1.2em'  }}>
            Once your tokens are locked, you can track the status through our easy-to-use dashboard. You'll receive notifications when the time or price conditions you set have been met. At that point, you can simply click the unlock button to retrieve your tokens. For every successful unlock, Moon or Dust takes a small 2% fee as a service charge.</Typography>

          <Typography variant="body1" style={{ color: '#ffffff', marginLeft: '10px', marginTop: '20px', fontSize: '1.2em'  }}>
            If you ever need your tokens before the conditions are met, there is an emergency withdrawal option. However, using this feature will come with a 50% fee, making it a last resort for urgent cases.</Typography>

          <Typography variant="body1" style={{ color: '#ffffff', marginLeft: '10px', marginTop: '20px', fontSize: '1.2em'  }}>
            Moon or Dust is designed to provide flexibility and peace of mind, ensuring your tokens are safe while giving you the ability to set strategic conditions for unlocking them based on your needs.</Typography>

          {/* Right Bottom Background Icon */}
          <AutoGraphIcon
            style={{
              position: 'absolute',
              right: '10px',
              bottom: '10px',
              fontSize: '10rem', // Adjust size as needed
              color: '#ffffff20', // Use transparency for a background effect
            }}
          />
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default CenterModule;
