import React from 'react';
import CenterModule from "../components/CenterModuleMyLocks";
import {Container} from "@mui/material";
import {AppProps} from "../Shared";

const MyLocks: React.FC<AppProps> = ({ authStatus }) => {
  return (
    <Container>
      <CenterModule  authStatus={ authStatus } />
    </Container>
  );
};

export default MyLocks;
