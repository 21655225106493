import React from 'react';
import {Typography, Box, Container, Grid} from '@mui/material';
import {AppProps} from "../Shared";

const StaticTextPage: React.FC<AppProps> = ({authStatus}) => {
    return (
        <Container>
            <>
            <Box mt={4}>
                <Grid container spacing={2}>
                    {/* 4-column section */}
                    <Grid item xs={12} md={12} style={{paddingLeft: '30px'}}>
                        <Typography variant="h3"
                                    style={{color: '#fff', marginBottom: '20px', fontWeight: '900'}}>Terms
                            and Conditions</Typography>

                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            Welcome to Moon or Dust (hereinafter referred to as "the Platform"). By using the Platform
                            to
                            lock tokens for a specific period or based on a price condition, you agree to abide by these
                            Terms and Conditions. The owners of Moon or Dust, including but not limited to the entities
                            operating under <b>moonordust.io</b> ("the Owners"), reserve the right to update these Terms
                            at
                            any time.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>1. Nature of the
                            Platform</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            Moon or Dust is a token-locking service that enables users to lock their tokens for a
                            predetermined duration or until certain price conditions are met. The tokens and time
                            information are stored on a blockchain, and price conditions are verified off-chain.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>2. User
                            Responsibility</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            It is your responsibility to ensure that you fully understand the risks involved in locking
                            your
                            tokens. Cryptocurrency prices can be volatile, and while Moon or Dust provides a locking
                            mechanism, it cannot predict or guarantee future price movements. By using the Platform, you
                            agree that you have verified the accuracy of the token contract addresses and any other
                            transaction details before initiating a lock. You are responsible for the safety of your
                            wallet
                            and private keys. Moon or Dust does not have access to your private keys and will never ask
                            you
                            for them.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>3. Off-Chain Price
                            Checking</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            The price conditions for unlocking tokens are checked off-chain using third-party data
                            providers. The Owners are not responsible for inaccuracies or delays in the price data
                            provided.
                            Any discrepancies in the off-chain price checks are beyond the control of the Platform.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>4. No Liability for
                            Losses</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            The Owners of Moon or Dust are not liable for any losses, financial or otherwise, resulting
                            from:
                            <ul>
                                <li>Volatility in cryptocurrency prices;</li>
                                <li>Errors in smart contract interactions, including but not limited to incorrect token
                                    addresses;
                                </li>
                                <li>Delays, technical failures, or inaccuracies in off-chain price data;</li>
                                <li>Any form of hacking, unauthorized access, or theft of tokens.</li>
                            </ul>
                            You agree that using the Platform is at your own risk, and the Owners provide no guarantees
                            or
                            assurances regarding the future value of locked tokens.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>5. Fees</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            There may be fees associated with using the Platform, including network transaction fees and
                            platform fees for locking and unlocking tokens. You agree to pay any applicable fees in
                            connection with your use of the Platform.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>6. Changes to the
                            Terms</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            The Owners reserve the right to modify or update these Terms at any time. Continued use of
                            the
                            Platform after changes have been made signifies your acceptance of the updated Terms.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>7.
                            Indemnification</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            You agree to indemnify and hold harmless the Owners from any claims, losses, damages,
                            liabilities, and expenses arising from your use of the Platform.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>8. Finality of
                            Transactions</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            All transactions initiated on the blockchain through the Platform are final and
                            irreversible.
                            The Owners cannot reverse or cancel any transaction once it has been confirmed on the
                            blockchain.
                        </Typography>

                        <Typography variant="h6" style={{color: '#f1f0e1', marginTop: '20px'}}>9.
                            Jurisdiction</Typography>
                        <Typography variant="body1" style={{color: '#9c9b93', marginBottom: '20px'}}>
                            These Terms and Conditions shall be governed by the laws of the jurisdiction in which the
                            Owners
                            operate. Any disputes shall be resolved in accordance with the legal framework of that
                            jurisdiction.
                        </Typography>

                        <Typography variant="body1" style={{color: '#9c9b93', marginTop: '20px'}}>
                            By using the Moon or Dust Platform, you acknowledge that you have read, understood, and
                            agreed
                            to these Terms and Conditions.
                        </Typography>

                        <Typography variant="body1" style={{color: '#9c9b93', marginTop: '20px'}}>
                            For any questions or concerns, please contact <b>support@moonordust.io</b>.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            </>
        </Container>
);
};

export default StaticTextPage;
