import React, {useEffect} from 'react';
import {Box, FormControlLabel, Slider, Typography} from '@mui/material';
import {INFINITY_DAYS, shortNumber} from "../Shared";

interface TokenInfo {
    price: number;      // Current token price
    marketCap: number;  // Current token market cap
    sliderValue: number; // Multiplier value (e.g., 1x, 10x, 100x, etc.)
    onChange: (value: number) => void; // Callback function to update slider value
    tokensToLock: string;
    timeToLock: number;
}

const PriceCapSlider: React.FC<TokenInfo> = ({price, marketCap, sliderValue, onChange, tokensToLock, timeToLock}) => {

    // Custom value mapper for multiplier
    const customValueMapper = (value: number) => {
        if (value === 0) return 0; // Inactive
        if (value <= 50) return 1 + ((value / 50) * 9); // 1x to 10x
        if (value <= 75) return (10 + ((value - 50) / 25) * 90); // 10x to 100x
        return (100 + ((value - 75) / 25) * 900); // 100x to 1000x
    };

    const inverseCustomValueMapper = (value: number) => {
        if (value === 0) return 0;
        if (value < 10) return ((value - 1) / 9) * 50; // 1x to 10x
        if (value <= 100) return ((value - 10) / 90) * 25 + 50; // 10x to 100x
        return ((value - 100) / 900) * 25 + 75; // 100x to 1000x
    };

    // Price and Market Cap calculation based on multiplier
    const calculatedPrice = (multiplier: number) => (price * multiplier);
    const calculatedMarketCap = (multiplier: number) => (marketCap * multiplier).toFixed(0);

    // Handle slider change and pass the updated value to the parent component
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        onChange(customValueMapper(newValue as number));
    };

    const formatDays = (value: number) => {
        if (value < 365) {
            return `${value} day${value === 1 ? '' : 's'}`;
        } else {
            const years = Math.floor(value / 365);
            return `${years} year${years > 1 ? 's' : ''}`;
        }
    };

    useEffect(() => {

    }, [tokensToLock]);

    return (
        <Box mt={4} mb={2} pl={4} pr={5}>
            <Typography variant="h6" style={{color: '#ffffff', marginBottom: '10px'}}>
                Multiplier: <b>{sliderValue > 0 ? sliderValue.toFixed(2) + 'x' : 'Inactive'}</b>
            </Typography>

            <Slider
                value={inverseCustomValueMapper(sliderValue)}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${customValueMapper(value).toFixed(2)}x`}
                min={0}
                max={100}
                marks={[
                    {value: 0, label: 'Inactive'},
                    {value: 50, label: '10x'},
                    {value: 75, label: '100x'},
                    {value: 100, label: '1000x'}
                ]}
                step={1}
                sx={{
                    color: '#ff007a', // Custom color for the slider
                    '& .MuiSlider-thumb': {
                        backgroundColor: '#ff007a', // Custom thumb color
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#9c9b93', // Custom rail color
                    },
                    '& .MuiSlider-markLabel': {
                        color: '#ff007a', // Custom mark label color
                    },
                }}
            />

            {/* Display calculated Price and Market Cap */}
            <Typography variant="h6" style={{color: '#ffffff', marginTop: '10px', fontSize: '1em'}} pt={4}>
                {timeToLock === INFINITY_DAYS && sliderValue === 0 ? '🔒 This configuration will lock your tokens forever.' : (
                    <>
                        <span style={{marginRight: '5px'}}>ℹ️</span> You can unlock your tokens
                        {sliderValue > 0 && (
                            <>
                                &nbsp;at a price of <b>${calculatedPrice(sliderValue)}</b> (market
                                cap: <b>${shortNumber(calculatedMarketCap(sliderValue))}</b>)
                            </>
                        )}
                        {timeToLock > 0 && timeToLock < INFINITY_DAYS ? (
                            <>
                                {sliderValue > 0 ? ' or ' : ' '}after <b>{formatDays(timeToLock)}</b>.
                            </>
                        ) : (
                            sliderValue > 0 ? '.' : ' anytime.'
                        )}
                    </>
                )}
            </Typography>
            <Typography variant="h6" mt={4}>
                💎 The value of your locked tokens will be: <b
                className={"pink-underline"}>${((Number(tokensToLock) * calculatedPrice(sliderValue)).toFixed(2))}</b>
            </Typography>
        </Box>
    );
};

export default PriceCapSlider;
