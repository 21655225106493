import React, {useEffect, useState} from 'react';
import {Badge, Box, Button, ButtonProps, styled} from '@mui/material';
import {Link} from 'react-router-dom';
import {useAccount} from 'wagmi';
import {AppProps} from "../Shared";

const StyledButton = styled((props: any & { component?: React.ElementType }) => (
    <Button {...props} />
))(({theme}) => ({
    // backgroundColor: '#2a053c',
    color: '#ffffff',
    borderRadius: '0px',
    textTransform: 'none',
    padding: '8px 16px',
    // '&:hover': {
    //   backgroundColor: '#343435',
    // },
    marginRight: '10px',
    fontWeight: 'bold',
    '&.Mui-disabled': {
        color: '#ffffff'
    }
}));

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#fff',
        color: '#2a053c',
    },
}));

const TopNav: React.FC<AppProps> = ({authStatus}) => {
    const {isConnected, address} = useAccount();
    const [locks, setLocks] = useState<number>(0);

    const fetchLocks = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/my-locks', {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            let locksCounter = 0;
            if(data.locks && data.locks.length) {
                data.locks.map((lock: any) => {
                    // count locks with status 'unlockable'
                    if(lock.status === 'unlockable') {
                        locksCounter++;
                    }
                });
            }
            setLocks(locksCounter);
        } catch (error) {
            console.error('Error fetching locks:', error);
        }
    };

    useEffect(() => {
        if(address && isConnected && authStatus === "authenticated") {
            fetchLocks();
        }
    }, [authStatus]);

    return (
        <Box display="flex" alignItems="center" mr={2} style={{marginTop: '00px'}}>
            <StyledButton component={Link} to="new-lock" style={{color: isConnected ? '#ffffff' : 'rgb(152, 147, 156)'}}
                          disabled={!isConnected}>
                <span style={{
                    marginRight: '5px',
                    color: isConnected ? 'rgb(255, 0, 122)' : 'rgb(152, 147, 156)'
                }}>+</span> NEW LOCK
            </StyledButton>
            {isConnected && (
                <StyledBadge badgeContent={locks}>
                    <StyledButton component={Link} to="my-locks">MY LOCKS</StyledButton>
                </StyledBadge>
            )}
        </Box>
    );
};

export default TopNav;
