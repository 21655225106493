import React from 'react';
import { Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import TopCenter from './TopCenter';
import TopNav from './TopNav';
import { useAccount } from 'wagmi';
import {AppProps} from "../Shared";

const TopRow: React.FC<AppProps> = ({authStatus}) => {
  const { isConnected } = useAccount();

  return (
    <>
      {/* Full width Box with bottom border */}
      <Box style={{
        width: '100vw',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        backgroundColor: '#101015',
        borderBottom: '1px solid #ffffff' // Full width bottom border
      }}>
        <Container style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px'
        }}>
          {/* Logo */}
            <Box
              maxWidth="200px"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Link to="/">
                <img
                  src="/logowhite.png"
                  alt="App Logo"
                  style={{ width: '100%', marginLeft: '20px' }}
                />
              </Link>
            </Box>

          {/* Top Center */}
          <TopCenter />

          {/* Right Side with Navigation and Connect Button */}
          <Box display="flex" alignItems="center">
            <TopNav authStatus={authStatus} />
            <Box display="flex" alignItems="center" ml={2}>
              {isConnected ? (
                <ConnectButton showBalance={false} label="LOGOUT" />
              ) : (
                <ConnectButton label="SIGN IN" />
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default TopRow;
