import React from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { shortNumber } from "../Shared";

interface BalanceInfo {
  balance: bigint;          // User's token balance
  sliderValue: number;       // Current value of the slider (percentage)
  decimals: number;       // Current value of the slider (percentage)
  onChange: (value: number) => void; // Callback function to update slider value
}

const TokenBalanceSlider: React.FC<BalanceInfo> = ({ balance, sliderValue, decimals, onChange }) => {

  // Calculate the amount of tokens to lock based on the percentage value from the slider
  const getTokenAmount = (value: number) => {
    return (balance * BigInt(value)) / BigInt(100) / BigInt(Math.pow(10, decimals));
  };

  // Handle slider change and pass the updated value to the parent component
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  return (
    <Box mt={4} mb={6} pl={4} pr={5}>
      <Typography variant="h6" style={{ color: '#ffffff', marginBottom: '10px' }}>
        Tokens to lock: {getTokenAmount(sliderValue).toString()}
      </Typography>

      <Slider
        value={sliderValue}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${shortNumber(getTokenAmount(value).toString())}`}
        min={1}
        max={100}
        marks={[
          { value: 1, label: '1%' },
          { value: 25, label: '25%' },
          { value: 50, label: '50%' },
          { value: 75, label: '75%' },
          { value: 100, label: '100%' }
        ]}
        step={1}
        sx={{
          color: '#ff007a', // Custom color for the slider
          '& .MuiSlider-thumb': {
            backgroundColor: '#ff007a', // Custom thumb color
          },
          '& .MuiSlider-rail': {
            backgroundColor: '#9c9b93', // Custom rail color
          },
          '& .MuiSlider-markLabel': {
            color: '#ff007a', // Custom mark label color
          },
        }}
      />
    </Box>
  );
};

export default TokenBalanceSlider;
