import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createConfig, http, useAccount, useDisconnect, WagmiConfig, WagmiProvider} from "wagmi";
import {mainnet} from "wagmi/chains";
import {
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";
import {
    connectorsForWallets,
    createAuthenticationAdapter,
    getDefaultConfig,
    RainbowKitAuthenticationProvider
} from "@rainbow-me/rainbowkit";
import {SiweMessage} from 'siwe';
import {checkAuthStatus} from "./Shared";
import {
    coinbaseWallet,
    metaMaskWallet,
    walletConnectWallet,
    trustWallet,
    xdefiWallet, okxWallet, phantomWallet
} from "@rainbow-me/rainbowkit/wallets";

declare module 'wagmi' {
    interface Register {
        config: typeof config
    }
}

Object.defineProperty(BigInt.prototype, "toJSON", {
    get() {
        "use strict";
        return () => String(this);
    }
});

(mainnet.rpcUrls.default.http as unknown as string[]) = [process.env.REACT_APP_ETH_NODE as string];

// Replace this with your own node URL
const nodeUrl = process.env.REACT_APP_ETH_NODE;//'https://ethereum.publicnode.com';

const connectors = connectorsForWallets(
    [
        {
            groupName: 'Recommended',
            wallets: [metaMaskWallet, walletConnectWallet, coinbaseWallet, trustWallet, xdefiWallet, okxWallet, phantomWallet],
        },
    ],
    {appName: 'Moon or Dust', projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ? process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID.toString() : "PROJECT_ID"},
);

const config = createConfig({
    chains: [mainnet],
    connectors,
    transports: {
        [mainnet.id]: http(nodeUrl),
    },
})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
});


const authenticationAdapter = (address: any, disconnect: any, setAuthStatus: any) => createAuthenticationAdapter({
    getNonce: async () => {
        if (!address) {
            return null;
        }
        const customHeaders = {
            "Content-Type": "application/json",
        }
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/auth/nonce',
            {
                method: "POST",
                headers: customHeaders,
                body: JSON.stringify({
                    address
                }),
                credentials: 'include', // Include cookies in the request
            });
        const responseData = await response.json();
        if (responseData.nonce) {
            return responseData.nonce;
        } else {
            console.log("E: got nonce", responseData);
            return null;
        }
    },

    createMessage: ({nonce, address, chainId}) => {
        return new SiweMessage({
            domain: window.location.host,
            address,
            statement: `Moon or Dust login: ${nonce}`,
            uri: window.location.origin,
            version: '1',
            chainId,
            nonce,
        });
    },

    getMessageBody: ({message}) => {
        return message.prepareMessage();
    },

    verify: async ({message, signature}) => {
        const verifyRes: any = await fetch(process.env.REACT_APP_API_URL + '/api/auth/signature', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({address: message.address, message, signature}),
            credentials: 'include', // Include cookies in the request
        });
        if (verifyRes.ok) {
            setAuthStatus("authenticated"); // Immediately set authStatus to authenticated after verify succeeds
            return true;
        } else {
            return false;
        }
    },

    signOut: async () => {
        await fetch(process.env.REACT_APP_API_URL + '/api/auth/logout', {
            credentials: 'include', // Include cookies in the request
        });

        await disconnect();

        setAuthStatus('unauthenticated');
    },
});


const AuthenticationWrapper: React.FC = () => {
    const [authStatus, setAuthStatus] = useState<'loading' | 'authenticated' | 'unauthenticated'>('loading');
    const {address, isConnected} = useAccount();
    const {disconnect} = useDisconnect();

    useEffect(() => {
        const fetchAuthStatus = async () => {
            const status = await checkAuthStatus();

            if (status === 'unauthenticated' && isConnected) {
                await disconnect(); // Disconnect the wallet to invalidate the session
                setAuthStatus('unauthenticated');
            } else {
                setAuthStatus(status);
            }

        };
        fetchAuthStatus();
    }, []);

    useEffect(() => {
        if (isConnected && address && authStatus === "authenticated") {
            setAuthStatus('authenticated');
        } else {
            setAuthStatus('unauthenticated');
        }
    }, [isConnected, address, authStatus]);

    // Show loading screen while checking auth status
    if (authStatus === 'loading') {
        return <div>Loading...</div>;
    }

    return (
        <RainbowKitAuthenticationProvider
            adapter={authenticationAdapter(address, disconnect, setAuthStatus)}
            status={authStatus}
        >
            <App authStatus={authStatus}/>
        </RainbowKitAuthenticationProvider>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <AuthenticationWrapper/>
            </QueryClientProvider>
        </WagmiProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
